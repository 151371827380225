import Vue from 'vue';
import {
  email,
  minLength,
  maxLength,
  required,
  requiredIf,
  sameAs,
} from 'vuelidate/lib/validators';

export default Vue.extend({
  computed: {
    firstNameError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { firstName } = (this as any).$v.formData;

      if (!firstName.$dirty) {
        return errors;
      }

      if (!firstName.maxLength) {
        errors.push('The first name cannot exceed 32 characters.');
      }
      if (!firstName.required) {
        errors.push('First name is required.');
      }

      return errors;
    },
    lastNameError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { lastName } = (this as any).$v.formData;

      if (!lastName.$dirty) {
        return errors;
      }

      if (!lastName.maxLength) {
        errors.push('The last name cannot exceed 32 characters.');
      }
      if (!lastName.required) {
        errors.push('Last name is required.');
      }

      return errors;
    },
    emailError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { email: emailAddress } = (this as any).$v.formData;

      if (!emailAddress.$dirty) {
        return errors;
      }

      if (!emailAddress.required) {
        errors.push('Email is required.');
      }
      if (!emailAddress.email) {
        errors.push('Please enter in a valid email address.');
      }

      return errors;
    },
    oldPasswordError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { oldPassword, newPassword } = (this as any).$v.formData;

      if (!newPassword.$dirty) {
        return errors;
      }

      if (!oldPassword.required) {
        errors.push('Your current password is required.');
      }

      return errors;
    },
    newPasswordError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { newPassword } = (this as any).$v.formData;

      if (!newPassword.$dirty) {
        return errors;
      }

      if (!newPassword.minLength) {
        errors.push('The password must be at least 12 characters.');
      }
      if (!newPassword.required) {
        errors.push('A new password is required.');
      }

      return errors;
    },
    repeatPasswordError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { repeatPassword } = (this as any).$v.formData;

      if (!repeatPassword.$dirty) {
        return errors;
      }

      if (!repeatPassword.sameAsPassword) {
        errors.push('Password must match the new password.');
      }

      return errors;
    },
  },
  validations: {
    formData: {
      firstName: {
        required,
        maxLength: maxLength(32),
      },
      lastName: {
        required,
        maxLength: maxLength(32),
      },
      email: { email, required },
      oldPassword: {
        required: requiredIf((model) => !!model.newPassword),
      },
      newPassword: {
        minLength: minLength(12),
        required: requiredIf((model) => !!model.oldPassword),
      },
      repeatPassword: {
        sameAsPassword: sameAs('newPassword'),
      },
      emailNotifications: {},
      slackNotifications: {},
    },
  },
});
