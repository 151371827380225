import { AxiosResponse } from 'axios';

import { SlackInfo } from '@/models/slack';
import httpClient from './http-client';

export const getSlackInfo = (orgId: string | number): Promise<SlackInfo> => {
  return httpClient
    .get(`/slackapp/info?o=${orgId}`)
    .then((response) => response.data);
};

export const postDisableSlack = (
  orgId: string | number,
): Promise<AxiosResponse> => {
  return httpClient
    .post('/slackapp/disable', { o: orgId })
    .then((response) => response.data);
};
