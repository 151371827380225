export interface AuthenticatorEnrollmentTicket {
  ticket_id: string;
  ticket_url: string;
}

export enum AuthenticatorStatus {
  Pending = 'pending',
  Confirmed = 'confirmed',
}

export interface Authenticator {
  id: AuthenticatorID;
  status: `${AuthenticatorStatus}`;
}

export interface OTPAuthenticator extends Authenticator {
  authenticator_type: AuthenticatorType.OTP;
}

export enum AuthenticatorType {
  OOB = 'oob',
  OTP = 'otp',
  RecoveryCode = 'recovery-code',
}

export type AuthenticatorID = string;
