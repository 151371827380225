import { UserUUID } from '@ax/api-clients-accounts/models';
import { AxiosResponseError } from '@ax/api-clients-common/models';
import { UserStorage } from '@/models/authentication';
import { showStandardHttpErrorNotification } from '@/utils/util';
import { getBespokeAuthHttpClient } from '.';

const USERS_ENDPOINT = '/users';
const STORAGE_ENDPOINT = 'storage';

function getURL(userId: UserUUID) {
  return `${USERS_ENDPOINT}/${userId}/${STORAGE_ENDPOINT}`;
}

export function putUserStorage(
  userId: UserUUID,
  data: UserStorage,
): Promise<UserStorage> {
  return getBespokeAuthHttpClient()
    .put<UserStorage>(getURL(userId), data)
    .then(({ data }) => data);
}

export function getUserStorage(userId: UserUUID): Promise<UserStorage> {
  return getBespokeAuthHttpClient()
    .get<UserStorage>(getURL(userId), { notificationsEnabled: false })
    .then(({ data }) => data)
    .catch((errorResponse?: AxiosResponseError) => {
      if (errorResponse) {
        const { status, data: { code } = {} } = errorResponse;
        if (status === 404 && code === 'response-status-exception') {
          return {};
        }
      }
      showStandardHttpErrorNotification(errorResponse);
      return Promise.reject(errorResponse);
    });
}
