import { computed, ref, watch, SetupContext } from '@vue/composition-api';
import { Route } from 'vue-router';

import { SlackInfo } from '@/models/slack';
import { getSlackInfo, postDisableSlack } from '@/services/slack.service';

export default function useSlack(context: SetupContext, hasPermission) {
  const route = computed(() => context.root.$route);
  const info = ref<SlackInfo>({});
  const error = ref<string>('');
  const loading = ref<boolean>(false);

  watch(
    [route, hasPermission],
    ([nextRoute, nextPermission]) => {
      const { query } = nextRoute as Route;
      const orgId = query.org || query.o;
      if (orgId && nextPermission) {
        fetchSlackInfo(orgId as string);
      }
    },
    {
      immediate: true,
    },
  );

  function fetchSlackInfo(orgId: string | number) {
    loading.value = true;
    error.value = '';

    getSlackInfo(orgId)
      .then((response: SlackInfo) => {
        info.value = response;
        loading.value = false;
      })
      .catch((responseError) => {
        info.value = {};
        error.value = responseError;
        loading.value = false;
      });
  }

  function disableSlackForOrg(orgId: string | number) {
    loading.value = true;
    error.value = '';

    postDisableSlack(orgId)
      .then(() => {
        fetchSlackInfo(orgId);
      })
      .catch((responseError) => {
        error.value = responseError;
        loading.value = false;
      });
  }

  return { info, error, loading, disableSlackForOrg };
}
