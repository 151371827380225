// TODO: move to separate workspace and refactor as plugin in Milestone 2 story: ATH-938
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

interface Auth0ClientConfig {
  client_id: string;
  domain: string;
}

export function useAuth0(clientConfig: Promise<Auth0ClientConfig>) {
  let client_id: string | undefined;
  let domain: string | undefined;
  let auth0Client: Auth0Client | undefined;

  async function initAuth0Client() {
    ({ domain, client_id } = await clientConfig);
    auth0Client = await createAuth0Client({
      domain,
      client_id,
      useRefreshTokens: true,
    });
  }
  const clientPromise = initAuth0Client();

  async function stepUpAuthForRemoveAuthenticator(login_hint: string) {
    await clientPromise;
    if (auth0Client) {
      const options = {
        scope: 'remove:authenticators',
        audience: `https://${domain}/mfa/`,
      };
      await auth0Client.loginWithPopup({
        ...options,
        login_hint,
        max_age: 0,
        acr_values:
          'http://schemas.openid.net/pape/policies/2007/06/multi-factor',
      });
      const claims = await auth0Client.getIdTokenClaims(options);
      const { amr = [] } = claims
        ? // Auth0 IdToken incorrectly types the amr property; it can be a string array
          (claims as { amr?: string | string[] })
        : {};
      const amrArray = Array.isArray(amr) ? amr : [amr];
      const gaveMFA = amrArray.some((val) => val === 'mfa');

      if (!gaveMFA) {
        throw new Error(`Could not validate MFA claim for user ${login_hint}`);
      }

      return auth0Client.getTokenSilently(options);
    }
  }

  async function deleteAuthenticator(
    authenticatorId: string,
    userEmail: string,
  ) {
    const mfaAccessToken = await stepUpAuthForRemoveAuthenticator(userEmail);
    return fetch(`https://${domain}/mfa/authenticators/${authenticatorId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${mfaAccessToken}`,
      },
    });
  }

  async function endAuth0Session() {
    await clientPromise;
    return auth0Client?.logout({ returnTo: window.location.href });
  }

  return { deleteAuthenticator, endAuth0Session };
}
